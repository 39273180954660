import React from "react";

import Intro from "./Intro";

import "../assets/styles/App.scss";

const App = () => {
  return (
    <div className="personal-website">
      <Intro />
      {/* <Resume /> */}
    </div>
  );
};

export default App;
